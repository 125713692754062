.outer-progress {
    display:block;
    width: 150px;
    height: 75px;
    background: #1e607d;
    margin: auto;
    position:absolute;
    left:0;right:0;bottom:0;top:0;
    color: black;
    border-radius: 75px 75px 0 0;
    overflow:hidden;
}

.outer-progress:after {
    display:block;
    content: "";
    z-index:1;
    width: 150px;
    height: 75px;
    margin: auto;
    position:absolute;
    left:0;right:0;bottom:0;top:0;
    border-radius: 75px 75px 0 0;
    box-shadow: inset 0 0 50px rgba(0,0,0, 0.5);
}

.progress-frame {
    position: absolute;
    left:0;right:0;
    bottom:0;
    display:block;
    width: 160px;
    height: 85px;
    margin: auto;
    border-radius: 80px 80px 0 0;
    background: linear-gradient(140deg, #b7d8e1, #558dc9);
}

.inner-progress {
    display:block;
    width: 150px;
    height:150px;
    position: absolute;
    top:0; left:0;
    transform:rotate(0.1turn);
    transition: transform 1s;
}
.inner-progress:before {
    display: block;
    content: "";
    width:150px;
    height: 75px;
    position:absolute;
    bottom:0px;
    left:0px;
    background: #cb5f5f;
}
.inner-progress:after {
    display:block;
    content: "";
    position:absolute;
    left:0px;
    width:0px;
    background:transparent;
    height:0px;
    top: 70px;
    border-radius:0px 0px 0px 0px;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-right: 75px solid white;
    border-radius:5px;
}