.questionScreen {
    max-width: 440px;
    margin: 20px auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
}

.questionScreen > h1 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 128px;
}
.bottomBar {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 85px;
}
.answers
{
    position: absolute;
    top: 128px;
    bottom: 85px;
    align-content: center;
    list-style-type: none;
    padding: 0 10px ;
    display:flex;
    gap:10px;
    justify-content: center;
    flex-wrap: wrap;
}

.answers > .pill > span {
    white-space: pre-wrap;
    text-align: center;
    overflow: hidden;
    line-height: 16px;
    min-height:18px;
    top: auto;
    bottom: auto;
}
