.resultsScreen {
    max-width: 1024px;
    margin: 20px auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
}

.resultsScreen > h1 {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 128px;
}

.resultsScreen > h1 > img {
    width: 128px
}

.resultsScreen > h1 > span {
    display: inline-block
}

.resultsScreen ul {
    list-style-type: none;
}


.resultsScreen button {
    margin: 20px auto 10px auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.resultsScreen .results {
    position: absolute;
    top: 128px;
    bottom: 85px;
    align-content: center;
    left: 0;
    right: 0;
    overflow-y: auto

}

ul.scores {
    max-width: 512px;
    margin: 0 auto;
    padding:0;
}

ul.resultAnswers {
    margin: 0 auto;
    padding:0;
}

li.questionRow, li.scoreRow {
    padding: 5px 20px;
    display: flex;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

span.scoreName, span.questionText {
    display: block;
    flex: 2;
    text-align: left;
}

span.scoreScore, span.answer {
    display: block;
    flex: 1;
    margin-top: 5px;
    text-align: right;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #175479;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3c9dc2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8fccda;
}
