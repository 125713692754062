p, h1 {
    margin: 0;
}

h1 {
    font-size: 30px;
    font-weight: normal;
}

* {
    box-sizing: border-box
}

.pill {
    display: flex;
    align-items: center;
    position: relative;
    width: 200px;
    background: rgb(22, 80, 117);
    background: linear-gradient(140deg, rgba(22, 80, 117, 1) 0%, rgba(30, 98, 126, 1) 100%);
    padding: 10px 20px;
    height: 55px;
    border-radius: 28px;
    border: 5px solid transparent;
    text-align: center;
    font-size: 18px;
    color: white;
    background-clip: padding-box !important;
}

.pill:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    border-radius: inherit;
    background: linear-gradient(140deg, #b7d8e1, #558dc9);
}

.pill > span {
    position: absolute;
    top: 10px;
    right: 20px;
    bottom: 10px;
    left: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    line-height:25px;
}

button.pill {
    background: rgb(196, 136, 37);
    background: linear-gradient(-40deg, rgb(102 55 40) 0%, rgba(196, 136, 37, 1) 100%);
}

button.pill:before {
    background: linear-gradient(140deg, #fdfbc6, #fbcd0f);
}

button.pill:hover {
    border-color: rgba(255,255,255,0.5);
}
button.pill:active {
    /*background: linear-gradient(140deg, rgb(102 55 40) 0%, rgba(196, 136, 37, 1) 100%);*/
    box-shadow: inset 2px 3px 5px rgb(0 0 0 / 50%);
}

button.pill-chosen {
    box-shadow: inset 2px 3px 5px rgb(0 0 0 / 50%);
    background: rgb(22, 80, 117);
    background: linear-gradient(140deg, rgba(22, 80, 117, 1) 0%, rgba(30, 98, 126, 1) 100%);
}


button.pill-chosen:before {
    background: linear-gradient(140deg, #b7d8e1, #558dc9);
}

button.pill-disabled:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: -5px;
    background:#1a2233;
    opacity:0.5;
}
